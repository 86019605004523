
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import accountService from "@/services/account-service";
import Header from "@/components/header.vue"; // @ is an alias to /src
import PopUp from "@/components/PopUp.vue";

@Component({
    components: {
        Header, PopUp,
    },
})
export default class Employee extends Vue {
    account_salaries: string = "";
    account_service: string = "";
    account_allowance: string = "";
    basic: string = "";
    monthly_allowance: string = "";
    non_monthly_allowance: string = "";
    overtime: string = "";
    monthly_deduction: string = "";
    non_monthly_deduction: string = "";
    absent_deduction: string = "";
    salary_and_wages: string = "";


    salary_index = -1;
    service_index = -1;
    allowance_index = -1;
    basic_index = -1;
    monthly_allowance_index = -1;
    non_monthly_allowance_index = -1;
    overtime_index = -1;
    monthly_deduction_index = -1;
    non_monthly_deduction_index = -1;
    absent_deduction_index = -1;
    salary_and_wages_index = -1


    account_data: any = []
    loading = false;
    error: string[] = []
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end
    selected_accured_salaries: any = [];
    accured_salaries: any = {
        title: "",
        id: null
    }
    selected_end_of_service: any = []
    end_of_service: any = {
        title: "",
        id: null
    }
    selected_vl_allowances: any = []
    vl_allowances: any = {
        title: "",
        id: null
    }
    selected_basic: any = []
    basic_acc: any = {
        title: "",
        id: null
    }
    selected_monthly_allowance: any = []
    monhtly_allowance_acc: any = {
        title: "",
        id: null
    }
    selected_non_monthly_allowance: any = []
    non_monhtly_allowance_acc: any = {
        title: "",
        id: null
    }
    selected_overtime: any = []
    overtime_acc: any = {
        title: "",
        id: null
    }
    selected_monthly_deduction: any = []
    monthly_deduction_acc: any = {
        title: "",
        id: null
    }
    selected_non_monthly_deduction: any = []
    non_monthly_deduction_acc: any = {
        title: "",
        id: null
    }
    selected_absent_deduction: any = []
    absent_deduction_acc: any = {
        title: "",
        id: null
    }

    selected_salary_and_wages: any = []
    salary_and_wages_acc: any = {
        title: "",
        id: null
    }


    search_accured_salaries(title: any, index: any) {
        this.salary_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleNode(title)
                .then((res) => {
                    this.selected_accured_salaries = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_accured_salaries = [];
        }
    }
    get_accured_salaries(data: any) {
        this.accured_salaries.id = data.id;
        this.accured_salaries.title = data.title
        this.selected_accured_salaries = [];
    }
    search_end_of_service(title: any, index: any) {
        this.service_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleNode(title)
                .then((res) => {
                    this.selected_end_of_service = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_end_of_service = [];
        }
    }
    get_end_of_service(data: any) {
        this.end_of_service.id = data.id;
        this.end_of_service.title = data.title
        this.selected_end_of_service = [];
    }
    get_vl_allowance(data: any) {
        this.vl_allowances.id = data.id;
        this.vl_allowances.title = data.title
        this.selected_vl_allowances = [];
    }
    search_vl_allowance(title: any, index: any) {
        this.allowance_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleNode(title)
                .then((res) => {
                    this.selected_vl_allowances = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_vl_allowances = [];
        }
    }
    get_basic(data: any) {
        this.basic_acc.id = data.id;
        this.basic_acc.title = data.title
        this.selected_basic = [];
    }
    search_basic(title: any, index: any) {
        this.basic_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleMaster(title)
                .then((res) => {
                    this.selected_basic = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_basic = [];
        }
    }
    get_ma(data: any) {
        this.monhtly_allowance_acc.id = data.id;
        this.monhtly_allowance_acc.title = data.title
        this.selected_monthly_allowance = [];
    }
    search_ma(title: any, index: any) {
        this.monthly_allowance_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleMaster(title)
                .then((res) => {
                    this.selected_monthly_allowance = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_monthly_allowance = [];
        }
    }
    get_nma(data: any) {
        this.non_monhtly_allowance_acc.id = data.id;
        this.non_monhtly_allowance_acc.title = data.title
        this.selected_non_monthly_allowance = [];
    }
    search_nma(title: any, index: any) {
        this.non_monthly_allowance_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleMaster(title)
                .then((res) => {
                    this.selected_non_monthly_allowance = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_non_monthly_allowance = [];
        }
    }
    get_ot(data: any) {
        this.overtime_acc.id = data.id;
        this.overtime_acc.title = data.title
        this.selected_overtime = [];
    }
    search_ot(title: any, index: any) {
        this.overtime_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleMaster(title)
                .then((res) => {
                    this.selected_overtime = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_overtime = [];
        }
    }
    get_md(data: any) {
        this.monthly_deduction_acc.id = data.id;
        this.monthly_deduction_acc.title = data.title
        this.selected_monthly_deduction = [];
    }
    search_md(title: any, index: any) {
        this.monthly_deduction_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleMaster(title)
                .then((res) => {
                    this.selected_monthly_deduction = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_monthly_deduction = [];
        }
    }
    get_nmd(data: any) {
        this.non_monthly_deduction_acc.id = data.id;
        this.non_monthly_deduction_acc.title = data.title
        this.selected_non_monthly_deduction = [];
    }
    search_nmd(title: any, index: any) {
        this.non_monthly_deduction_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleMaster(title)
                .then((res) => {
                    this.selected_non_monthly_deduction = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_non_monthly_deduction = [];
        }
    }
    get_absent_deduction(data: any) {
        this.absent_deduction_acc.id = data.id;
        this.absent_deduction_acc.title = data.title
        this.selected_absent_deduction = [];
    }
    search_absent_deduction(title: any, index: any) {
        this.absent_deduction_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleMaster(title)
                .then((res) => {
                    this.selected_absent_deduction = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_absent_deduction = [];
        }
    }

    get_salary_and_wages(data: any) {
        this.salary_and_wages_acc.id = data.id;
        this.salary_and_wages_acc.title = data.title
        this.selected_salary_and_wages = [];
    }
    search_salary_and_wages(title: any, index: any) {
        this.salary_and_wages_index = index;
        if (title.length > 2) {
            this.loading = true;
            accountService.getByTitleMaster(title)
                .then((res) => {
                    this.selected_salary_and_wages = res.data.result;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        }
        if (title.length == 0) {
            this.selected_salary_and_wages = [];
        }
    }

    mounted() { this.retreive() }
    retreive() {
        accountService.getAccountMapping().then((resp) => {
            this.account_data = resp.data
            for (const item of resp.data) {
                if (item.title === 'Accrued Salaries Account') {
                    this.account_salaries = item.account_title
                    this.accured_salaries.title = item.account_title
                    this.accured_salaries.id = item.account
                }
                if (item.title === 'End of Service Award Provision') {
                    this.account_service = item.account_title
                    this.end_of_service.title = item.account_title
                    this.end_of_service.id = item.account

                }
                if (item.title === "VL allowance Provision") {
                    this.account_allowance = item.account_title
                    this.vl_allowances.title = item.account_title
                    this.vl_allowances.id = item.account
                }
                //here

                if (item.title === "Basic Account") {
                    this.basic = item.account_title
                    this.basic_acc.title = item.account_title
                    this.basic_acc.id = item.account
                }
                if (item.title === "Monthly Allowance Account") {
                    this.monthly_allowance = item.account_title
                    this.monhtly_allowance_acc.title = item.account_title
                    this.monhtly_allowance_acc.id = item.account
                }
                if (item.title === "Non Monthly Allowance Account") {
                    this.non_monthly_allowance = item.account_title
                    this.non_monhtly_allowance_acc.title = item.account_title
                    this.non_monhtly_allowance_acc.id = item.account
                }
                if (item.title === "Overtime Account") {
                    this.overtime = item.account_title
                    this.overtime_acc.title = item.account_title
                    this.overtime_acc.id = item.account
                }
                if (item.title === "Monthly Deduction Account") {
                    this.monthly_deduction = item.account_title
                    this.monthly_deduction_acc.title = item.account_title
                    this.monthly_deduction_acc.id = item.account
                }
                if (item.title === "Non Monthly Deduction Account") {
                    this.non_monthly_deduction = item.account_title
                    this.non_monthly_deduction_acc.title = item.account_title
                    this.non_monthly_deduction_acc.id = item.account
                }
                if (item.title === "Absent Deduction Account") {
                    this.absent_deduction = item.account_title
                    this.absent_deduction_acc.title = item.account_title
                    this.absent_deduction_acc.id = item.account
                }

                if (item.title === "Salary And Wages") {
                    this.salary_and_wages = item.account_title
                    this.salary_and_wages_acc.title = item.account_title
                    this.salary_and_wages_acc.id = item.account
                }

            }
        })
    }
    submit(payload: any) {
        if (!payload || Object.keys(payload).length === 0) {
            this.showModal("Selected accounts are already saved.", [], "error")
        } else {
            for (const item of payload) {
                accountService.saveAccountMapping(item).then((resp) => {
                    this.retreive();
                    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-employee-account-settings");
                    this.showModal("Created Successfully", [], "success");
                }).catch((err) => {
                    console.error('Unexpected error:', err);
                    this.showModal('An unexpected error occurred', [], "error");
                })
            }
        }
    }
    validData(): boolean {
        this.error = []
        // console.log(this.vl_allowances)
        if (this.accured_salaries.id === null || this.accured_salaries.id === undefined) {
            this.error.push('Please Select Accrued Salaries Account')
        }
        if (this.end_of_service.id === undefined || this.end_of_service.id === null) {
            this.error.push('End of Service Award Provision')

        }
        if (this.vl_allowances.id === null || this.vl_allowances.id === undefined) {
            this.error.push('VL allowance Provision')
        }
        //here
        if (this.basic_acc.id === null || this.basic_acc.id === undefined) {
            this.error.push('Basic Account')
        }
        if (this.monhtly_allowance_acc.id === null || this.monhtly_allowance_acc.id === undefined) {
            this.error.push('Monthly Allowance Account')
        }
        if (this.non_monhtly_allowance_acc.id === null || this.non_monhtly_allowance_acc.id === undefined) {
            this.error.push('Non Monthly Allowance Account')
        }
        if (this.overtime_acc.id === null || this.overtime_acc.id === undefined) {
            this.error.push('Overtime Account')
        }
        if (this.monthly_deduction_acc.id === null || this.monthly_deduction_acc.id === undefined) {
            this.error.push('Monthly Deduction Account')
        }
        if (this.non_monthly_deduction_acc.id === null || this.non_monthly_deduction_acc.id === undefined) {
            this.error.push('Non Monthly Deduction Account')
        }
        if (this.absent_deduction_acc.id === null || this.absent_deduction_acc.id === undefined) {
            this.error.push('Absent Deduction Account')
        }
        if (this.salary_and_wages_acc.id === null || this.salary_and_wages_acc.id === undefined) {
            this.error.push('Salary And Wages')
        }
        if (this.error.length > 0) {
            return false
        }
        else {
            return true
        }
    }
    save() {
        if (this.validData()) {
            // Check if the resources already exist in this.account_data
            const existingAccruedSalaries = this.account_data.find((item: any) => item.title === 'Accrued Salaries Account');
            const existingEndOfService = this.account_data.find((item: any) => item.title === 'End of Service Award Provision');
            const existingVLAllowance = this.account_data.find((item: any) => item.title === 'VL allowance Provision');

            const existingBasicAccount = this.account_data.find((item: any) => item.title === 'Basic Account');
            const existingMonthlyAllowance = this.account_data.find((item: any) => item.title === 'Monthly Allowance Account');
            const existingNonMonthlyAllowance = this.account_data.find((item: any) => item.title === 'Non Monthly Allowance Account');
            const existingOvertime = this.account_data.find((item: any) => item.title === 'Overtime Account');
            const existingMonthlyDeduction = this.account_data.find((item: any) => item.title === 'Monthly Deduction Account');
            const existingNonMonthlyDeduction = this.account_data.find((item: any) => item.title === 'Non Monthly Deduction Account');
            const existingAbsentDeduction = this.account_data.find((item: any) => item.title === 'Absent Deduction Account');
            const existingSalaryAndWages = this.account_data.find((item: any) => item.title === 'Salary And Wages');


            const payloads = [];
            if (!existingAccruedSalaries) {
                payloads.push({
                    title: 'Accrued Salaries Account',
                    account: this.accured_salaries.id,
                });
            }
            else if (existingAccruedSalaries && existingAccruedSalaries.account !== this.accured_salaries.id) {
                payloads.push({
                    title: 'Accrued Salaries Account',
                    account: this.accured_salaries.id,
                })
            }
            if (!existingEndOfService) {
                payloads.push({
                    title: 'End of Service Award Provision',
                    account: this.end_of_service.id,
                });
            }
            else if (existingEndOfService && existingEndOfService.account !== this.end_of_service.id) {
                payloads.push({

                    title: 'End of Service Award Provision',
                    account: this.end_of_service.id,
                })
            }
            if (!existingVLAllowance) {
                payloads.push({
                    title: 'VL allowance Provision',
                    account: this.vl_allowances.id,
                });
            }
            else if (existingVLAllowance && existingVLAllowance.account !== this.vl_allowances.id) {
                payloads.push({

                    title: 'VL allowance Provision',
                    account: this.vl_allowances.id,
                })
            }
            // Here
            if (!existingBasicAccount) {
                payloads.push({
                    title: 'Basic Account',
                    account: this.basic_acc.id,
                });
            }
            else if (existingBasicAccount && existingBasicAccount.account !== this.basic_acc.id) {
                payloads.push({
                    title: 'Basic Account',
                    account: this.vl_allowances.id,
                })
            }
            if (!existingMonthlyAllowance) {
                payloads.push({
                    title: 'Monthly Allowance Account',
                    account: this.monhtly_allowance_acc.id,
                });
            }
            else if (existingMonthlyAllowance && existingMonthlyAllowance.account !== this.monhtly_allowance_acc.id) {
                payloads.push({

                    title: 'Monthly Allowance Account',
                    account: this.monhtly_allowance_acc.id,
                })
            }
            if (!existingNonMonthlyAllowance) {
                payloads.push({
                    title: 'Non Monthly Allowance Account',
                    account: this.vl_allowances.id,
                });
            }
            else if (existingNonMonthlyAllowance && existingNonMonthlyAllowance.account !== this.non_monhtly_allowance_acc.id) {
                payloads.push({

                    title: 'Non Monthly Allowance Account',
                    account: this.non_monhtly_allowance_acc.id,
                })
            }
            if (!existingOvertime) {
                payloads.push({
                    title: 'Overtime Account',
                    account: this.overtime_acc.id,
                });
            }
            else if (existingOvertime && existingOvertime.account !== this.overtime_acc.id) {
                payloads.push({

                    title: 'Overtime Account',
                    account: this.overtime_acc.id,
                })
            }
            if (!existingMonthlyDeduction) {
                payloads.push({
                    title: 'Monthly Deduction Account',
                    account: this.monthly_deduction_acc.id,
                });
            }
            else if (existingMonthlyDeduction && existingMonthlyDeduction.account !== this.monthly_deduction_acc.id) {
                payloads.push({

                    title: 'Monthly Deduction Account',
                    account: this.monthly_deduction_acc.id,
                })
            }
            if (!existingNonMonthlyDeduction) {
                payloads.push({
                    title: 'Non Monthly Deduction Account',
                    account: this.non_monthly_deduction_acc.id,
                });
            }
            else if (existingNonMonthlyDeduction && existingNonMonthlyDeduction.account !== this.non_monthly_deduction_acc.id) {
                payloads.push({

                    title: 'Non Monthly Deduction Account',
                    account: this.non_monthly_deduction_acc.id,
                })
            }
            if (!existingAbsentDeduction) {
                payloads.push({
                    title: 'Absent Deduction Account',
                    account: this.absent_deduction_acc.id,
                });
            }
            else if (existingAbsentDeduction && existingAbsentDeduction.account !== this.absent_deduction_acc.id) {
                payloads.push({

                    title: 'Absent Deduction Account',
                    account: this.absent_deduction_acc.id,
                })
            }
            if (!existingSalaryAndWages) {
                payloads.push({
                    title: 'Salary And Wages',
                    account: this.salary_and_wages_acc.id,
                });
            }
            else if (existingSalaryAndWages && existingSalaryAndWages.account !== this.salary_and_wages_acc.id) {
                payloads.push({

                    title: 'Salary And Wages',
                    account: this.salary_and_wages_acc.id,
                })
            }
            this.submit(payloads);
        }
    }
}
